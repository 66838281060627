import React from "react"
import { Link } from "gatsby"
import { linkResolver } from "../../util"

const PopularTopics = ({ topics }) => {
  const topicLinks = topics.map(topic => (
    <Link
      className="popular-topics__link-btn"
      key={topic.slug.current}
      to={linkResolver({ _type: "article_type", slug: topic.slug.current })}
    >
      {topic.article_type_title}
    </Link>
  ))

  return (
    <>
      <div className="is-hidden-mobile popular-topics">
        <span className="popular-topics__title">popular topics:</span>
        {topicLinks}
      </div>
    </>
  )
}

export default PopularTopics
