import React, { useContext } from "react"
import ShopButton from "./shop-btn"
import { VeraImage } from "../../elements/vera-image"
import { Link } from "gatsby"
import { StoreContext } from "../../../context/store-context"
import { bgColorResolver, colorResolver } from "../../../util"

const Product = ({
  product_name,
  product_image,
  product_variants,
  _id,
  none,
  slug,
  product_collection,
  product_flavor_profile,
  collection_page,
  product_label,
  product_disabled,
}) => {
  const { cart } = useContext(StoreContext)

  const labels = () => {
    const labelEl = product_label.map((label, i) => {
      return (
        <span
          key={`label.label_title__${i}`}
          className={`${bgColorResolver(
            label.label_color.section_background
          )} ${colorResolver(
            label.label_color.section_background
          )} product__label`}
        >
          {label.label_title}
        </span>
      )
    })
    return (
      <div className="product__label-wrapper">
        <div className="product__label-controller">{labelEl}</div>
      </div>
    )
  }

  if (
    !product_variants ||
    !product_variants.variants ||
    product_variants.variants.length < 1
  ) {
    return null
  }
  const inCart = cart
    .filter(item => item._id === _id)
    .filter(item => item.variant._key === product_variants.variants[0]._key)
  const price = product_variants.variants[0].variant_info_price

  return (
    <div
      className={`product ${none ? "product--none" : ""} ${
        inCart.length > 0 ? "product__in-cart" : ""
      } ${collection_page ? "product__collection" : ""}`}
    >
      <div className="product__image-wrapper">
        <Link to={`/shop/${product_collection.slug.current}/${slug.current}/`}>
          <VeraImage
            asset={product_image.asset}
            contain
            className="transform-animate"
          />
        </Link>
        {labels()}
      </div>
      <div className="product__title has-text-centered">
        <Link to={`/shop/${product_collection.slug.current}/${slug.current}/`}>
          {product_name}
        </Link>
      </div>
      <div className="product__price has-text-centered">{price} DKK</div>
      <div
        className="product__info"
        style={
          !product_flavor_profile ||
          !product_flavor_profile.score ||
          !product_flavor_profile.score[0]
            ? { justifyContent: "center" }
            : {}
        }
      >
        {product_flavor_profile &&
          product_flavor_profile.score &&
          product_flavor_profile.score[0] && (
            <div className="flavor-profile">
              <span className="product__info-price" style={{ marginRight: 5 }}>
                <strong>{product_flavor_profile.score[0].title}:</strong>
              </span>
              <span className="product-info__score" style={{ marginBottom: 2 }}>
                {profileScore(
                  product_flavor_profile.score[0].title,
                  product_flavor_profile.score[0].score
                )}
              </span>
            </div>
          )}
        <div className="product__info-price">{price} DKK</div>
      </div>
      <div className={`product__background-overlay`}></div>
      <ShopButton
        _id={_id}
        product_name={product_name}
        variant={product_variants.variants[0]}
        variant_type={product_variants.variant_type}
        product_image={product_image}
        product_disabled={product_disabled}
        inCart={inCart}
        link={`/shop/${product_collection.slug.current}/${slug.current}/`}
      />
    </div>
  )
}

export default Product

export const profileScore = (title, score) => {
  const items = []
  for (let i = 0; i < 6; i++) {
    if (i < score)
      items.push(
        <span key={`${title}-${i}`} className="product-info__score--filled" />
      )
    else {
      items.push(
        <span key={`${title}-${i}`} className="product-info__score--empty" />
      )
    }
  }
  return items
}
