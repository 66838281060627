import React, { useContext } from "react"
import { StoreContext } from "../../../context/store-context"

const ShopButton = ({
  _id,
  variant,
  variant_type,
  product_name,
  product_image,
  link,
  inCart,
  product_disabled,
}) => {
  const { cart, addToCart, removeFromCart } = useContext(StoreContext)
  const addItem = e => {
    e.preventDefault()
    const item = {
      _id,
      link,
      product_name,
      product_image,
      variant,
      variant_type,
    }
    addToCart(item)
  }

  const removeItem = e => {
    e.preventDefault()
    const item = {
      _id,
      product_name,
      variant,
    }
    removeFromCart(item)
  }

  return (
    <>
      <div className="product__shop-control">
        {inCart.length > 0 ? (
          <>
            <button className="shop-btn" onClick={removeItem}>
              <i className="icon-minus" />
            </button>
            <span className="amount-btn">{inCart.length}</span>
            <button className="shop-btn" onClick={addItem}>
              <i className="icon-plus" />
            </button>
          </>
        ) : (
          <>
            <button
              onClick={addItem}
              disabled={product_disabled}
              className="shop-btn"
            >
              <i className="icon-plus" />
            </button>
          </>
        )}
      </div>
      <div
        className="product__shop-amount"
        style={{ visibility: inCart.length > 0 ? "visible" : "hidden" }}
      >
        <span className="amount-btn">{inCart.length}</span>
      </div>
    </>
  )
}

export default ShopButton
