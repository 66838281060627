import React from "react"
import { VeraImage } from "../elements/vera-image"
import { Link } from "gatsby"

const RecipeTeaser = ({ slug, title, asset }) => {
  const video = () => {
    if (!asset.video) {
      return ""
    }
    const id = asset.video.split("=")[1]
    return `https://img.youtube.com/vi/${id}/hqdefault.jpg`
  }
  return (
    <Link to={`/recipe/${slug}`}>
      <div className="recipe-teaser">
        <div className="text-overlay--recipe">Recipe</div>
        <div className="text-overlay--title">{title}</div>
        {asset && asset.video && (
          <img
            style={{ width: "100%" }}
            className="article-teaser__image"
            src={video()}
          />
        )}
        {asset && !asset.video && asset.image && (
          <VeraImage
            imgStyle={{ borderRadius: "20px" }}
            className="image transform-animate"
            asset={asset.image.asset}
          />
        )}
      </div>
    </Link>
  )
}

export default RecipeTeaser
