import React from "react"
import { graphql } from "gatsby"
import Layout from "../../components/layout"
import CollectionStructureComponents from "../../components/product/collection-structure-components"
import SEO from "../../components/seo"

const CollectionStructureTemplate = ({ data, pageContext, location }) => {
  const { field_title } = data.sanityShopSiteStructure
  return (
    <Layout>
      <SEO title={field_title} url={location.pathname} />
      <CollectionStructureComponents data={data} pageContext={pageContext} />
    </Layout>
  )
}

export default CollectionStructureTemplate

export const query = graphql`
  query($collectionSlugs: [String!], $slug: String!) {
    sanityShopSiteStructure(slug: { current: { eq: $slug } }) {
      field_title
      intro_text
    }
    allSanityCollection(
      filter: { slug: { current: { in: $collectionSlugs } } }
    ) {
      nodes {
        featured_recipe {
          slug {
            current
          }
          recipe_title
          recipe_image_image {
            image {
              asset {
                fluid {
                  ...GatsbySanityImageFluid
                }
              }
            }
            video
          }
        }
      }
    }
    allSanityProduct(
      filter: {
        product_collection: { slug: { current: { in: $collectionSlugs } } }
      }
    ) {
      nodes {
        _id
        product_name
        slug {
          current
        }
        _type
        product_flavor_profile {
          score {
            title
            score
          }
        }
        product_variants {
          variant_type
          variants {
            _key
            variant_info_price
            variant_info_value
          }
        }
        product_collection {
          collection_title
          slug {
            current
          }
        }
        product_label {
          label_title
          label_color {
            section_background
          }
        }
        product_disabled
        product_image {
          asset {
            fluid(maxWidth: 500) {
              ...GatsbySanityImageFluid
            }
          }
        }
      }
    }
  }
`
